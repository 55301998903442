import React from 'react'

const TodoFilter = ({onShow}) => {
  return (
    <div className='todo-filter'>
        <select onClick={(e) => onShow(e.target.value)}>
          <option value="all"> Show All </option>
          <option value="complete"> Completed Tasks </option>
          <option value="incomplete"> Incompleted Tasks </option>
        </select>
    </div>
  )
}

export default TodoFilter