import TodoForm from "./Components/TodoForm";
import TodoItem from "./Components/TodoItem";
import TodoActions from "./Components/TodoActions";
import TodoFilter from  "./Components/TodoFilter";
import { useEffect, useState, useRef } from "react";
import NoResult from "./Assets/Images/no-result-icon.svg";
import {v4 as uuidv4} from 'uuid';

function App() {

  // Get the Todo List from Local Storage
  const TodoListContent = JSON.parse(localStorage.getItem("todoListContent")) ? JSON.parse(localStorage.getItem("todoListContent")) : [];

  // Store the all tasks
  let [tasks, setTasks] = useState(TodoListContent);
  let [isCompleted, setIsCompleted] = useState(false);
  let [isIncompleted, setIsIncompleted] = useState(false);
  let [isEmpty, setIsEmpty] = useState(false);
  const selectAllRef = useRef();


  // Set the Todo List to Local Storage
  useEffect(function() {
    localStorage.setItem("todoListContent", JSON.stringify(tasks));
  }, [tasks]);


  // Add new task
  const handleAddNewTask = (task) => {
    if (task.trim() !== "") {
      setTasks([
        ...tasks,
        {
          id: uuidv4(),
          task: task.trim(),
          isCompleted: false,
          isChecked: false
        }
      ])
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }

  // Toggle Check
  const handleCheckbox = (id) => {
    setTasks(
      tasks.map((item) => {
        if(item.id === id) {
          return {
            ...item,
            isChecked: !item.isChecked
          }
        } else {
          return item;
        }
      })
    );
    selectAllRef.current.checked = false;
  }

  // Delete selected items
  const handleDelete = (e) => {
    e.preventDefault();
    let newTasks = tasks.filter((item) => !item.isChecked);
    setTasks(newTasks);
    selectAllRef.current.checked = false;
  }

  // Complete selected items
  const handleComplete = (e) => {
    e.preventDefault();

    setTasks(
      tasks.map((item) => {
        if(item.isChecked) {
          return {
            ...item,
            isChecked: !item.isChecked,
            isCompleted: true
          }
        } else {
          return item;
        }
      })
    );
    selectAllRef.current.checked = false;
  }

  // Select ALl
  const handleSelectAll = (val) => {
    console.log(val);
    setTasks(
      tasks.map((item) => {
        return {
          ...item,
          isChecked: val
        }
      })
    )
  }

  const handleShow = (val) => {
    if (val === "complete") {
      setIsCompleted(true);
      setIsIncompleted(false);
    } else if(val === "incomplete") {
      setIsCompleted(false);
      setIsIncompleted(true);
    } else {
      setIsCompleted(false);
      setIsIncompleted(false);
    }
  }

  useEffect(() => {
    let checkTasks = tasks.some((item) => !item.isChecked);
    if (!checkTasks && tasks.length) {
      selectAllRef.current.checked = true;
    }
  }, [tasks]);


  let showTasks;
  if (isCompleted && !isIncompleted) {
    showTasks = tasks.filter(item => item.isCompleted)
  } else if(!isCompleted && isIncompleted) {
    showTasks = tasks.filter(item => !item.isCompleted)
  } else {
    showTasks = tasks
  }

  return (
    <section className="todo-wrapper" style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
      <TodoForm onAdd={handleAddNewTask} isEmpty={isEmpty} />
      <div className="todo-list">
        {
          tasks.length ? <div className="select-all">
            <div className="select-all-wrapper">
              <input type="checkbox" id="select-all" onChange={(e) => handleSelectAll(e.target.checked)} ref={selectAllRef} />
              <label htmlFor="select-all"> Select All </label>
            </div>
            <TodoFilter onShow={handleShow} />
          </div> : null
        }
          
        
        
        {
          showTasks.length ? showTasks.map((item) => {
            return <TodoItem key={item.id} taskContent={item.task} isChecked={item.isChecked} taskId={item.id} isCompleted={item.isCompleted} changeCheckbox={(e) => handleCheckbox(item.id)} />
          }) : <div className="no-result">
            <img src={NoResult} title="no result" alt="no result" />
            <p> No Results! </p>
          </div>
        }
      </div>
      {
        showTasks.length ? <TodoActions onDelete={handleDelete} onComplete={handleComplete} /> : null
      }
    </section>
  )
}

export default App;
