import React, {useState} from 'react';
import plusIcon from "../Assets/Images/plus-icon.png";

const TodoForm = ({ onAdd, isEmpty }) => {

  // Set the new task
  const [task, setTask] = useState("");

  // On Submit Form
  const handleTaskForm = (e) => {
    e.preventDefault();
    onAdd(task);
    setTask("");
  }

  return (
    <section className='todo-form-wrapper'>
        <h1 className='todo-title'> Todo List </h1>
        <form className='todo-form' onSubmit={handleTaskForm}>
            <div className='form-group'>
                <input type='text' className='form-control' placeholder='Enter your task...' onChange={(e) => setTask(e.target.value)} value={task} />
                { isEmpty ? <label className='error'> Please make sure you enter a valid value... </label> : null}
                <button type='submit' className='btn btn-primary add-btn' onClick={handleTaskForm}> 
                  <img src={plusIcon} title="Add a new task" alt='Add a new task' />
                </button>
            </div>
        </form>
    </section>
  )
}

export default TodoForm