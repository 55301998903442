import React from 'react'

const TodoItem = ({ taskContent, isChecked, changeCheckbox, taskId,isCompleted }) => {
  return (
    <div className='todo-item'>
        <input type='checkbox' id={taskId} className={` ${isCompleted ? 'completed-input' : ''}`} checked={isChecked ? true : false} onChange={changeCheckbox} />
        <label htmlFor={taskId} className={`form-label ${isCompleted ? 'completed-task' : ''}`}> { taskContent } </label>
    </div>
  )
}

export default TodoItem