import React from 'react'

const TodoActions = ({ onDelete, onComplete }) => {
  return (
    <div className='todo-functionality'>
        <div className='todo-actions'>
            <button className='btn btn-primary' onClick={onDelete}> Remove Selected </button>
            <button className='btn btn-primary' onClick={onComplete}> Complete Selected </button>
        </div>
    </div>
  )
}

export default TodoActions